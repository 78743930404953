import {
  ThemeColorVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import {
  CSSVarColumnWidthSmall,
  CSSVarHeaderHeight,
} from 'components/global-styles/GlobalStyles';
// eslint-disable-next-line import/no-named-as-default
import styled from 'styled-components';

export const StyledDesktopCart = styled.aside`
  background-color: ${ThemeColorVariable.Surface};
  border-left: 1px solid ${ThemeColorVariable.OnSurfaceBorder};
  color: ${ThemeColorVariable.OnSurface};
  display: flex;
  flex-direction: column;

  /* Height should be viewport height with header height subtracted */
  height: calc(100vh - ${CSSVarHeaderHeight});
  justify-content: space-between;
  max-height: calc(100vh - ${CSSVarHeaderHeight});
  min-height: calc(100vh - ${CSSVarHeaderHeight});
  min-width: ${CSSVarColumnWidthSmall};
  overflow-y: auto;
  width: 30%;
  z-index: 1;
`;

export const StyledCartRows = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: ${ThemeSpaceVariable.Medium};
  padding-top: 0;
`;
