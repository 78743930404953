/* eslint-disable jsx-a11y/alt-text */
// The image this rule ignores is decorative and does not need an alt attribute
import { useEffect } from 'react';
import { ProtectedRoutes, Routes, isProtectedRoute } from 'types/routes';
import { HamburgerButton } from 'components/route-wrapper/subcomponents/HamburgerButton';
import CookieModal from 'components/cookie-modal';
import { getCookieConsent } from 'helpers/cookie-helper/cookie-helper';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { useOnRouteChange } from 'hooks/useOnRouteChange';
import { AspectRatio, BreakPoint } from 'types/ui';
import { useGetHeaderTitle } from 'hooks/useGetHeaderTitle';
import { useCookieModalAPI } from 'store/cookieModalStore';
import { useAppStore } from 'store/appStore';
import { usePlaceStore } from 'store/placeStore';
import { RegisterUserModal } from 'components/register-user-modal';
import { SignInModal } from 'components/sign-in-modal/SignInModal';
// Ignore note: Utility import considered ok
// eslint-disable-next-line caspeco/discourage-chakra-import
import { useMediaQuery } from '@chakra-ui/react';
import { AspectRatioWrapper } from 'components/aspect-ratio-wrapper';
import { CartGuard } from 'components/cart-guard/CartGuard';
import { getLogoImage, getShowCoverImage } from './RouteWrapper.helpers';
import { PageTitleOrLogo } from './subcomponents/PageTitleOrLogo';
import Header from './subcomponents/Header';

interface IRouteWrapper {
  /** Internal type of route that the route wrapper is displayed for. */
  route: Routes;
  /** Page component */
  children: React.ReactNode;
}

/**
 * Wrapper for routes in the application. Renders header and global dialog components.
 */
function RouteWrapper({ children, route }: IRouteWrapper) {
  const { place } = usePlaceStore();
  const { appData } = useAppStore();
  const title = useGetHeaderTitle(route);
  const { setCookieModalOpen, setCookieModalClosed } = useCookieModalAPI();
  const [isLargeScreen] = useMediaQuery(`(min-width: ${BreakPoint.Small})`, {
    ssr: false,
  });

  useOnRouteChange(route);
  useScrollToTop();

  // Stored values
  const storedConsentCookie = Boolean(getCookieConsent());

  // Check if cookie modal should be opened or not, and update viewstate accordingly
  useEffect(() => {
    if (!setCookieModalClosed || !setCookieModalClosed) {
      return;
    }

    const shouldPromptCookieModal: boolean =
      isProtectedRoute(route) && !storedConsentCookie;

    if (shouldPromptCookieModal) {
      setCookieModalOpen();
    }

    if (!shouldPromptCookieModal) {
      setCookieModalClosed();
    }
  }, [storedConsentCookie, setCookieModalClosed, setCookieModalOpen, route]);

  const shouldShowCoverImage = getShowCoverImage(
    route,
    appData?.images,
    place?.imageMap,
  );

  const getLeftHeaderComponent = (): JSX.Element | null =>
    isProtectedRoute(route) ? (
      <HamburgerButton routeName={route as ProtectedRoutes} />
    ) : null;

  const getMiddleHeaderComponent = (): JSX.Element => {
    const logo = getLogoImage(shouldShowCoverImage, appData?.images);
    return <PageTitleOrLogo route={route} logo={logo} title={title} />;
  };

  const getCoverImage = (): JSX.Element | null => {
    if (!appData?.images?.cover || !shouldShowCoverImage) return null;

    const aspectRatio = isLargeScreen
      ? AspectRatio.ThirtyTwoByNine
      : AspectRatio.OneByOne;

    return (
      <AspectRatioWrapper aspectratio={aspectRatio}>
        <img
          style={{ zIndex: -1 }}
          srcSet={appData.images.cover.getScrSet(aspectRatio, 'l', 'xl')}
          fetchpriority="high"
          id="cover"
          width="100%"
          aria-hidden="true"
        />
      </AspectRatioWrapper>
    );
  };

  return (
    <>
      <CartGuard />
      <CookieModal />
      <RegisterUserModal />
      <SignInModal />
      <Header
        leftComponent={getLeftHeaderComponent()}
        middleComponent={getMiddleHeaderComponent()}
        coverImage={getCoverImage()}
      />
      {children}
    </>
  );
}

export default RouteWrapper;
