import { useTranslation } from 'react-i18next';
import {
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from '@caspeco/casper-ui-library.components.modal';
import { Heading } from '@caspeco/casper-ui-library.components.heading';
import { Button } from '@caspeco/casper-ui-library.components.button';
import { Flex } from '@caspeco/casper-ui-library.components.flex';
import {
  ThemeColorVariable,
  ThemeFontSizeVariable,
  ThemeFontVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { Box } from '@caspeco/casper-ui-library.components.box';
import OrderNumber from 'components/order-number/OrderNumber';
import { useOrderStore } from 'store/orderStore';
import { SkeletonOrderConfirmation } from 'components/skeletons/SkeletonOrderConfirmation';
import NotFound from 'components/not-found';
import OrderStatusStepper from 'components/order-status-stepper/OrderStatusStepper';
import { Summary } from 'components/summary';
import OrderMessages from 'components/order-messages/OrderMessages';
import OrderDetails from 'components/order-details/OrderDetails';
import { Icons } from '@caspeco/casper-ui-library.components.icon';
import { useOrderRequest } from './hooks/useOrderRequest';
import { HistoryOrderReceiptButton } from './subcomponents/HistoryOrderReceiptButton';

interface IHistoryModalOrder {
  orderId: string;
  isProcessed: boolean;
  onGoBack: () => void;
  toggleConfirmDelete: () => void;
}

export function HistoryModalOrder({
  orderId,
  isProcessed,
  onGoBack,
  toggleConfirmDelete,
}: IHistoryModalOrder) {
  const { t } = useTranslation();
  const { isLoading, error } = useOrderRequest(orderId);
  const { order } = useOrderStore();

  const onClickDelete = () => {
    toggleConfirmDelete();
  };

  let modalContent;

  if (isLoading)
    modalContent = (
      <ModalBody>
        <SkeletonOrderConfirmation />
      </ModalBody>
    );
  else if (error || !order)
    modalContent = (
      <>
        <ModalHeader p={ThemeSpaceVariable.Medium}>
          <Flex align="center" justify="space-between">
            <Button variant="ghost" onClick={onGoBack} leftIcon={Icons.Back}>
              {t('action_back_to_history')}
            </Button>
            <ModalCloseButton />
          </Flex>
        </ModalHeader>
        <ModalBody minH={{ base: '300px', md: '400px' }}>
          <NotFound
            icon={Icons.Receipt}
            title={t('error_get_order')}
            variant="surface"
          />
        </ModalBody>
      </>
    );
  else {
    const { orderNumber, messages, campaignDiscounts } = order;
    modalContent = (
      <>
        <ModalHeader p={ThemeSpaceVariable.Medium}>
          <Flex align="center" justify="space-between">
            <Button variant="ghost" onClick={onGoBack} leftIcon={Icons.Back}>
              {t('action_back')}
            </Button>
            <ModalCloseButton />
          </Flex>
        </ModalHeader>
        <ModalBody
          px={{ base: ThemeSpaceVariable.Large, md: ThemeSpaceVariable.XLarge }}
          pb={ThemeSpaceVariable.Large}
        >
          <Box mb={ThemeSpaceVariable.Medium}>
            <OrderNumber orderNumber={orderNumber} />
          </Box>
          <OrderStatusStepper isPolling={false} />
          {messages.length > 0 && <OrderMessages />}
          <OrderDetails />
          <Heading
            as="h3"
            fontSize={ThemeFontSizeVariable.Large}
            mt={ThemeSpaceVariable.Large}
            mb={ThemeSpaceVariable.Small}
            fontFamily={ThemeFontVariable.SubHeader}
            color={ThemeColorVariable.OnSurfaceHeader}
            fontWeight="medium"
          >
            {t('order_your')}
          </Heading>
          <Summary data={order} campaigns={campaignDiscounts} />
          <Flex
            gap={ThemeSpaceVariable.Medium}
            flexWrap="wrap"
            mt={ThemeSpaceVariable.Large}
          >
            <HistoryOrderReceiptButton />
            {isProcessed && (
              <Button
                type="button"
                variant="ghost"
                loadingText={t('action_remove')}
                onClick={onClickDelete}
                leftIcon={Icons.Delete}
              >
                {t('order_delete')}
              </Button>
            )}
          </Flex>
        </ModalBody>
      </>
    );
  }

  return modalContent;
}
