import { useTranslation } from 'react-i18next';

import { Text } from '@caspeco/casper-ui-library.components.text';
import {
  ThemeColorVariable,
  ThemeFontSizeVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { TStep } from '@caspeco/casper-ui-library.components.stepper';
import { DeliveryStatus } from 'types/order';
import { useBaseColorValue } from 'components/base-color-context';

export const PreparingStep = ({
  deliveryStatus,
  isPolling,
}: {
  deliveryStatus: DeliveryStatus | undefined;
  isPolling: boolean;
}): TStep => {
  const { t } = useTranslation();
  const { baseColor } = useBaseColorValue();

  let labelHeadingTextColor;
  let defaultIconColor;
  let defaultDividerAndStepColor;
  switch (baseColor) {
    case 'background':
      labelHeadingTextColor = ThemeColorVariable.OnBackgroundHeader;
      defaultIconColor = ThemeColorVariable.OnBackground;
      defaultDividerAndStepColor = ThemeColorVariable.OnBackgroundBorder;
      break;
    case 'surface':
    default:
      labelHeadingTextColor = ThemeColorVariable.OnSurfaceHeader;
      defaultIconColor = ThemeColorVariable.OnSurface;
      defaultDividerAndStepColor = ThemeColorVariable.OnSurfaceBorder;
      break;
  }

  const getIcon = () => {
    if (deliveryStatus === 'PREPARING') {
      if (isPolling) {
        return 'spinner';
      }
      return undefined;
    }
    if (deliveryStatus === 'DELIVERED' || deliveryStatus === 'READY') {
      return 'check';
    }
    return undefined;
  };

  return {
    label: (
      <Text
        fontWeight="medium"
        color={labelHeadingTextColor}
        fontSize={ThemeFontSizeVariable.Medium}
      >
        {t('delivery_status_preparing')}
      </Text>
    ),
    iconColor:
      deliveryStatus === 'NEW'
        ? defaultIconColor
        : ThemeColorVariable.OnSuccess,
    color:
      deliveryStatus === 'NEW'
        ? defaultDividerAndStepColor
        : ThemeColorVariable.Success,
    icon: getIcon(),
    dividerColor:
      deliveryStatus === 'PREPARING' || deliveryStatus === 'NEW'
        ? defaultDividerAndStepColor
        : ThemeColorVariable.Success,
  };
};
