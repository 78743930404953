import { SupportedLocale } from './locale';

export interface IBSTLUserResponse {
  userId?: string;
  name: string;
  emailAddress: string;
  phoneNumber?: string;
  locale: string;
  guestId: string;
}

/**
 * A registered user in the context of Online Order, BSTL.
 * Originates from a signed in Firebase user and shares the same userId.
 */
export class BSTLUser {
  name: string;
  emailAddress: string;
  phoneNumber?: string;
  locale: SupportedLocale;
  /**
   * Id originating from the corresponding Firebase user.
   */
  userId: string;
  /**
   * Custom shorter id used in the context of Online Order, BSTL,
   * where the user is referred to as a Guest.
   */
  guestId: string;

  constructor(values: IBSTLUserResponse) {
    this.userId = values?.userId ?? '';
    this.name = values?.name ?? '';
    this.emailAddress = values?.emailAddress ?? '';
    this.phoneNumber = values?.phoneNumber ?? '';
    this.locale = (values?.locale as SupportedLocale) ?? SupportedLocale.EN;
    this.guestId = values?.guestId;
  }
}

export interface IBSTLUserRequestValues {
  name?: string;
  emailAddress?: string;
  phoneNumber?: string;
  locale: string;
}
