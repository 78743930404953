import i18next from 'i18next';
import { BSTLErrorCode, IBSTLApiErrorResponse } from 'types/api-error';

/**
 * Get translated error for handled BSTL api error codes.
 * @returns translated exception or fallbacks to error message if no match is found.
 */
export const translateErrorCode = (key: BSTLErrorCode): string => {
  switch (key) {
    case 'MAX_QUANTITY_EXCEEDED':
      return i18next.t('api_error_max_quantity_exceeded');
    case 'CHANNEL_CLOSED':
      return i18next.t('api_error_channel_closed');
    case 'MENU_CLOSED':
      return i18next.t('api_error_menu_closed');
    case 'PAYMENT_INITIALIZATION_NOT_POSSIBLE':
      return i18next.t('api_error_payment_initialization_not_possible');
    case 'PAYMENT_FINALIZATION_NOT_POSSIBLE':
      return i18next.t('api_error_payment_finalization_not_possible');
    case 'PAYMENT_PROVIDER_DOES_NOT_EXIST':
      return i18next.t('api_error_payment_provider_does_not_exist');
    case 'MAX_TIP_AMOUNT_EXCEEDED':
      return i18next.t('api_error_max_tip_exceeded');
    case 'INVALID_READY_TIME':
      return i18next.t('api_error_invalid_ready_time');
    case 'ARTICLE_OUT_OF_STOCK':
      return i18next.t('api_error_article_sold_out');
    case 'CAMPAIGN_CODE_EXPIRED':
      return i18next.t('api_error_campaign_code_expired');
    case 'CAMPAIGN_CODE_ALREADY_USED':
      return i18next.t('api_error_campaign_code_already_used');
    case 'CAMPAIGN_INVALID_ARTICLE_COMBINATION':
      return i18next.t('api_error_campaign_invalid_article_combination');
    case 'CAMPAIGN_LOWER_AMOUNT_THAN_REQUIRED':
      return i18next.t('api_error_campaign_lower_amount_than_required');
    case 'DISABLED_MENU_ITEMS_FOUND_IN_CART':
      return i18next.t('api_error_disabled_menu_item_found_in_cart');
    // #region
    // The following three errors share a common invalid error message due to there not
    // being any considered benefit to the user to differentiate between them.
    case 'CAMPAIGN_CODE_INVALID':
    case 'CAMPAIGN_NOT_WITHIN_PERIOD':
    case 'CAMPAIGN_NOT_ACTIVE':
      return i18next.t('api_error_campaign_code_invalid');
    // #endregion
    default:
      return i18next.t('error_generic');
  }
};

/**
 * Use to get one string to represent potential multiple errors in response.

 * @param errorResponse API response data when a request status is not ok (400 - 499)
 * @param translate If true, will translate error code to user-friendly message.
 * 
 * @returns If multiple Error response contains multiple errors (rare), returns a string of the error messages
 * separating them with ','. For ex. 'Payment provider unavailable, Menu is closed'.
 * Or a single error 'Payment provider unavailable'.
 * If no error messages are found (unexpected behaviour), returns undefined.
 */
export const getBSTLApiErrorMessage = (
  errorResponse: IBSTLApiErrorResponse,
  translate: boolean = false,
): string => {
  if (!errorResponse?.errors || errorResponse?.errors.length === 0) {
    return '';
  }

  if (errorResponse.errors.length === 1) {
    return translate
      ? translateErrorCode(errorResponse.errors[0].key)
      : errorResponse.errors[0].message;
  }

  const messageArray = errorResponse.errors.map((e) =>
    translate ? translateErrorCode(e.key) : e.message,
  );
  return messageArray.join(', ');
};
