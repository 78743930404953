import { Cart, CartRow } from 'types/cart';
import { Order, OrderItem } from 'types/order';
import { PriceIncludingVat } from 'types/price';

/** Local interface for helper. */
interface ISummaryItem {
  id: string;
  name: string;
  options: string[];
  extras: string[];
  quantity: number;
  price: PriceIncludingVat;
}

/** Interace used for helper below and Summary-component ui. */
interface ISummary {
  items: ISummaryItem[];
  numberOfItems: number;
  totalPrice: PriceIncludingVat;
  tipAmount?: PriceIncludingVat;
  totalPriceExcludingTips: PriceIncludingVat;
  totalAmountForArticleRows: PriceIncludingVat;
}

const convertCartRowToCheckoutSummaryItem = (
  cartRow: CartRow,
): ISummaryItem => {
  const item: ISummaryItem = {
    id: cartRow.id,
    name: cartRow.article.name,
    options: [],
    extras: [],
    quantity: cartRow.quantity,
    price: cartRow.totalAmountForRow,
  };

  item.options = cartRow.article.optionGroups.flatMap((optionGroup) =>
    optionGroup.addons.map((option) => {
      return option.name;
    }),
  );

  item.extras = cartRow.article.extraGroups.flatMap((extraGroup) =>
    extraGroup.addons.map((extra) => {
      return extra.name;
    }),
  );

  return item;
};

const convertOrderItemToSummaryItem = (orderItem: OrderItem): ISummaryItem => {
  const item: ISummaryItem = {
    id: orderItem.id,
    name: orderItem.name,
    options: [],
    extras: [],
    quantity: orderItem.quantity,
    price: orderItem.itemTotalAmountIncludingVat,
  };

  item.options = orderItem.options.map((option) => {
    return option.name;
  });

  item.extras = orderItem.extras.map((extra) => {
    return extra.name;
  });

  return item;
};

/**
 * Turns either Cart or Order into a summery object used in summary component.
 *
 * Background: Depending on where in their purchase journey the user is,
 * we want to summarize a different purchase object, but with the same appearance.
 * BEFORE the user has paid we want a summary of the cart,
 * AFTER a successful payment has been made we want to summarize
 * the purchased order (the cart no longer exists).
 *
 * @param value Either a Cart or an Order.
 * @returns Generic summary object based on input value.
 */
export const getSummary = (value: Cart | Order): ISummary | undefined => {
  const summary: ISummary = {
    items: <ISummaryItem[]>[],
    numberOfItems: 0,
    totalPrice: new PriceIncludingVat({ cents: 0, currency: 'SEK' }),
    totalPriceExcludingTips: new PriceIncludingVat({
      cents: 0,
      currency: 'SEK',
    }),
    totalAmountForArticleRows: new PriceIncludingVat({
      cents: 0,
      currency: 'SEK',
    }),
  };

  if (value instanceof Cart) {
    summary.totalPrice = value.totalAmount;
    summary.totalPriceExcludingTips = value.totalAmountExcludingTip;
    summary.tipAmount = value.tipInfo.tipAmount;
    summary.items = value.rows.map((row) =>
      convertCartRowToCheckoutSummaryItem(row),
    );
    summary.numberOfItems = value.rows
      .map((rows) => rows.quantity)
      .reduce((prevQuantity, nextQuantity) => prevQuantity + nextQuantity, 0);
    summary.totalAmountForArticleRows = value.totalAmountForArticleRows;
    return summary;
  }

  if (value instanceof Order) {
    summary.totalPrice = value.paidAmount;
    summary.totalPriceExcludingTips = value.paidAmountExcludingTip;
    summary.tipAmount = value.tipAmount;
    summary.items = value.items.map((i) => convertOrderItemToSummaryItem(i));
    summary.numberOfItems = value.items
      .map((item) => item.quantity)
      .reduce((prevQuantity, nextQuantity) => prevQuantity + nextQuantity, 0);

    return summary;
  }

  return undefined;
};
