import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ThemeColorVariable,
  ThemeFontSizeVariable,
  ThemeFontVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { Stack } from '@caspeco/casper-ui-library.components.stack';
import { Button } from '@caspeco/casper-ui-library.components.button';
import { Text } from '@caspeco/casper-ui-library.components.text';
import {
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from '@caspeco/casper-ui-library.components.modal';
// eslint-disable-next-line import/no-named-as-default
import styled from 'styled-components';
import { Heading } from '@caspeco/casper-ui-library.components.heading';
import { Flex } from '@caspeco/casper-ui-library.components.flex';
import { IAccountFormFields } from './AccountForm.types';
import { EmailField } from './EmailField';
import { NameField } from './NameField';
import { PhoneField } from './PhoneField';

const StyledInlineTextButton = styled.button`
  color: ${ThemeColorVariable.OnSurfaceSubdued};
  text-decoration: underline;
  &:hover {
    color: ${ThemeColorVariable.OnSurface};
  }
`;

interface IAccountForm {
  onSubmit: (formData: IAccountFormFields) => Promise<void>;
  onClickDeleteAccount: () => void;
  defaultValues: IAccountFormFields;
}

export function AccountForm({
  onSubmit,
  defaultValues,
  onClickDeleteAccount,
}: IAccountForm) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<IAccountFormFields>({
    mode: 'onSubmit',
    defaultValues,
    reValidateMode: 'onChange',
  });

  return (
    <>
      <ModalHeader
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={ThemeSpaceVariable.Medium}
      >
        <Heading
          as="h2"
          fontSize={ThemeFontSizeVariable.XLarge}
          color={ThemeColorVariable.OnSurfaceHeader}
          fontFamily={ThemeFontVariable.SubHeader}
          fontWeight="medium"
        >
          {t('account_my_account')}
        </Heading>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody
        px={{
          base: ThemeSpaceVariable.Medium,
          md: ThemeSpaceVariable.XLarge,
        }}
      >
        <form
          id="account-form"
          name="account-form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate // Turn off native behaviour, handled by useForm
        >
          <Stack
            spacing={ThemeSpaceVariable.Medium}
            my={ThemeSpaceVariable.Large}
          >
            <EmailField control={control} isReadOnly />
            <NameField control={control} />
            <PhoneField control={control} />
          </Stack>

          <Text
            mt={ThemeSpaceVariable.Large}
            fontSize={ThemeFontSizeVariable.Small}
            color={ThemeColorVariable.OnSurfaceSubdued}
          >
            {t('account_if_you_want_to_remove_permanently')}
            <StyledInlineTextButton
              type="button"
              onClick={onClickDeleteAccount}
            >
              {t('misc_you_click_here')}
            </StyledInlineTextButton>
            {t('account_remove_yourself')}
          </Text>
          <Flex justify="flex-end" p={ThemeSpaceVariable.Medium}>
            <Button type="submit" variant="primary" isLoading={isSubmitting}>
              <span>{t('action_save')}</span>
            </Button>
          </Flex>
        </form>
      </ModalBody>
    </>
  );
}
