import { AspectRatio } from 'types/ui';
import { Flex } from '@caspeco/casper-ui-library.components.flex';
import {
  Skeleton,
  SkeletonText,
} from '@caspeco/casper-ui-library.components.skeleton';
import { Box } from '@caspeco/casper-ui-library.components.box';
import {
  ThemeBorderVariable,
  ThemeColorVariable,
  ThemeFontSizeVariable,
  ThemeRadiusVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { AspectRatioWrapper } from 'components/aspect-ratio-wrapper';
import { CSSVarColumnWidthLarge } from 'components/global-styles/GlobalStyles';

function SkeletonMenuCard() {
  return (
    <Flex
      gap={ThemeSpaceVariable.Medium}
      direction={{ base: 'column', sm: 'row' }}
      borderBottom={ThemeBorderVariable.XSmall}
      borderColor={ThemeColorVariable.OnBackgroundBorder}
      py={ThemeSpaceVariable.Medium}
    >
      <AspectRatioWrapper aspectratio={AspectRatio.SixteenByNine}>
        <Skeleton
          h={{ base: 'auto', sm: '111px' }}
          borderRadius={ThemeRadiusVariable.Medium}
        />
      </AspectRatioWrapper>
      <Box w="100%">
        <Skeleton
          h={ThemeFontSizeVariable.XLarge}
          mb={ThemeSpaceVariable.Small}
        />
        <SkeletonText
          noOfLines={2}
          skeletonHeight={ThemeFontSizeVariable.Medium}
        />
      </Box>
    </Flex>
  );
}

export default function SkeletonMenusList() {
  return (
    <Flex w="100%" justify="center">
      <Flex
        direction="column"
        w={{ base: '100%', sm: CSSVarColumnWidthLarge }}
        my={{ base: ThemeSpaceVariable.None, sm: ThemeSpaceVariable.Large }}
        px={{ base: ThemeSpaceVariable.Medium, md: ThemeSpaceVariable.None }}
      >
        {[...Array(4)].map((_entry, i) => (
          <SkeletonMenuCard key={i} />
        ))}
      </Flex>
    </Flex>
  );
}
