import { useTranslation } from 'react-i18next';
import { Button } from '@caspeco/casper-ui-library.components.button';
import { Text } from '@caspeco/casper-ui-library.components.text';
import { Stack } from '@caspeco/casper-ui-library.components.stack';
import {
  ThemeColorVariable,
  ThemeFontSizeVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
// eslint-disable-next-line import/no-named-as-default
import styled from 'styled-components';
import {
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from '@caspeco/casper-ui-library.components.modal';
import { Heading } from '@caspeco/casper-ui-library.components.heading';
import { Divider } from '@caspeco/casper-ui-library.components.divider';
import { getLocalStorage } from 'helpers/local-storage-helper/local-storage-helper';
import { Box } from '@caspeco/casper-ui-library.components.box';
import { useAuthStore } from 'store/authStore';
import { IAppGeneralFeatures } from 'types/appFeatures';
import { getAppGuestType } from 'helpers/app-helper/app-helper';
import { Icons } from '@caspeco/casper-ui-library.components.icon';

const StyledInlineTextButton = styled.button`
  color: ${ThemeColorVariable.OnSurfaceSubdued};
  text-decoration: underline;
  &:hover {
    color: ${ThemeColorVariable.OnSurface};
  }
`;

interface ISignInMethods {
  isSigningInWithGoogle: boolean;
  onSignInWithEmail: () => void;
  onSignInWithGoogle: () => void;
  onContinueAsGuest: () => void;
  onClickBecomeBusinessCustomer: () => void;
}

export function SignInMethods({
  isSigningInWithGoogle,
  onSignInWithEmail,
  onSignInWithGoogle,
  onContinueAsGuest,
  onClickBecomeBusinessCustomer,
}: ISignInMethods) {
  const { t } = useTranslation();
  const { isSignInModalManuallyOpened } = useAuthStore();
  const appFeatures =
    getLocalStorage<IAppGeneralFeatures>('appFeatures') ?? undefined;

  const showBecomeBusinessCustomer = Boolean(
    appFeatures?.b2bEnabled && appFeatures?.b2bEmailAddress,
  );

  const isRequiredToRegister = getAppGuestType() === 'REGISTERED';

  // Continue as guest should only show if sign in is optional
  // and the user did not themselves open the sign in modal
  // (opening the sign in modal manually would indicate they don't want to continue as guest)
  const showContinueAsGuest =
    getAppGuestType() === 'REGISTERED_AND_ANONYMOUS' &&
    !isSignInModalManuallyOpened;

  return (
    <>
      <ModalHeader>
        <ModalCloseButton isAbsolutePositioned />
      </ModalHeader>
      <ModalBody
        px={[ThemeSpaceVariable.Large, ThemeSpaceVariable.XLarge]}
        py={ThemeSpaceVariable.Large}
        textAlign="center"
      >
        <Heading
          as="h2"
          color={ThemeColorVariable.OnSurfaceHeader}
          mb={ThemeSpaceVariable.Medium}
        >
          {t('account_sign_in')}
        </Heading>
        <Stack
          spacing={ThemeSpaceVariable.Medium}
          my={ThemeSpaceVariable.Large}
        >
          {/* SIGN IN WITH APPLE NOT YET SUPPORTED */}
          <Button
            onClick={onSignInWithGoogle}
            variant="tertiary"
            isLoading={isSigningInWithGoogle}
            size="lg"
            leftIcon={Icons.Google}
          >
            <span>{t('account_continue_with_google')}</span>
          </Button>
          <Button
            onClick={onSignInWithEmail}
            variant="tertiary"
            size="lg"
            leftIcon={Icons.Email}
          >
            {t('account_continue_with_email')}
          </Button>
        </Stack>
        {showContinueAsGuest && (
          <Text
            color={ThemeColorVariable.OnSurfaceSubdued}
            my={ThemeSpaceVariable.Large}
          >
            {t('misc_you_can_also')}
            <StyledInlineTextButton onClick={onContinueAsGuest}>
              {t('account_continue_as_guest')}
            </StyledInlineTextButton>
          </Text>
        )}
        <Box py={ThemeSpaceVariable.Medium}>
          <Divider borderColor={ThemeColorVariable.OnSurfaceBorder} />
        </Box>
        {showBecomeBusinessCustomer && (
          <Button
            variant="link"
            onClick={onClickBecomeBusinessCustomer}
            my={ThemeSpaceVariable.Large}
          >
            {t('account_become_business_customer')}
          </Button>
        )}
        {isRequiredToRegister && (
          <Text
            color={ThemeColorVariable.OnSurfaceSubdued}
            fontSize={ThemeFontSizeVariable.Small}
            textAlign="left"
            my={ThemeSpaceVariable.Medium}
          >
            {t('account_sign_in_reasoning')}
          </Text>
        )}
      </ModalBody>
    </>
  );
}
