// eslint-disable-next-line import/no-named-as-default
import styled from 'styled-components';
import { BreakPoint } from 'types/ui';

interface IStyledContainer {
  center?: boolean;
}
const StyledContainer = styled.div<IStyledContainer>`
  height: 100%;
  margin: 0 auto;
  width: 100%;

  ${(props) =>
    props.center &&
    `
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
  `};

  @media (min-width: ${BreakPoint.Small}) {
    max-width: 620px;
  }

  @media (min-width: ${BreakPoint.Medium}) {
    max-width: 680px;
  }

  @media (min-width: ${BreakPoint.Large}) {
    max-width: 720px;
  }

  @media (min-width: ${BreakPoint.XLarge}) {
    max-width: 820px;
  }
`;
export default StyledContainer;
