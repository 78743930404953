import { getAppAlias } from 'helpers/app-helper/app-helper';

export type PaymentStatus =
  | 'CREATED'
  | 'PAYMENT_INITIALIZED'
  | 'PAID'
  | 'REFUNDED'
  | 'CANCELED';

export interface IInitiatePaymentResponse {
  orderId: string;
  /** Id unique for initiated payment, used to define a payment session. */
  providerOrderId: string;
  redirectUrl: string;
  redirectType: RedirectType;
  paymentStatus: PaymentStatus;
}

export class InitatetedPayment {
  orderId: string;
  providerOrderId: string;
  redirectUrl: string;
  redirectType: RedirectType;
  paymentStatus: PaymentStatus;

  constructor(values: IInitiatePaymentResponse) {
    this.orderId = values?.orderId;
    this.providerOrderId = values?.providerOrderId;
    this.redirectUrl = values?.redirectUrl;
    this.redirectType = values?.redirectType;
    this.paymentStatus = values?.paymentStatus;
  }
}

enum PaymentProvider {
  /**
   * Reepay is the old name for Billwerk.
   * They were first known by the name Reepay, which remains in our API.
   */
  Reepay = 'reepay',
  Simulator = 'simulator',
  CaspecoInvoice = 'caspecoinvoice',
}

export enum RedirectType {
  WEB,
}

/**
 * This is a selection of Billwerk (formerly known as Reepay) Payment Method id's supported by us.
 * Read more and see all at: https://optimize-docs.billwerk.com/docs/window-checkout
 */
export enum PaymentMethod {
  Card = 'CARD',
  Swish = 'SWISH',
  GooglePay = 'GOOGLE_PAY',
  ApplePay = 'APPLE_PAY',
  Vipps = 'VIPPS',
  Invoice = 'INVOICE',
}

export interface ICaspecoInvoiceCompany {
  customerId: number;
  contactId: number;
  name: string;
  sortOrder: number;
}

export interface IProviderPaymentMethodResponse {
  provider: string;
  paymentMethod: string;
  sortOrder: number;
  caspecoInvoiceCompanies: ICaspecoInvoiceCompany[];
}

export interface IProviderPaymentMethodValues {
  provider?: string;
  paymentMethod?: string;
  sortOrder?: number;
  caspecoInvoiceCompany?: ICaspecoInvoiceCompany | null;
}

export class ProviderPaymentMethod {
  readonly provider: PaymentProvider;
  readonly paymentMethod: PaymentMethod;
  readonly sortOrder: number;
  readonly caspecoInvoiceCompany: ICaspecoInvoiceCompany | null;

  constructor(values?: IProviderPaymentMethodValues) {
    this.provider = values?.provider as PaymentProvider;
    this.paymentMethod = values?.paymentMethod as PaymentMethod;
    this.sortOrder = values?.sortOrder ?? 0;
    this.caspecoInvoiceCompany = values?.caspecoInvoiceCompany ?? null;
  }
}

interface IBSTLPaymentRequestValues {
  orderId: string;
  providerPaymentMethod: ProviderPaymentMethod;
  /** Invoice marking */
  marking?: string;
  registeredUserPurchase: boolean;
}

interface ICaspecoInvoice {
  customerId: number;
  contactId: number;
  marking?: string;
}

export class BSTLPaymentRequest {
  paymentProviderId: PaymentProvider;
  /** @unused Saved token from previously used card (not currently available) */
  paymentToken: string;
  /** Url to redirect user to after payment is accepted in Billwerk (formerly known as Reepay) UI. */
  acceptUrl: string;
  /** Url to redirect user to when payment is canceled in Billwerk (formerly known as Reepay) UI. */
  cancelUrl: string;
  method: PaymentMethod;
  caspecoInvoice: null | ICaspecoInvoice;

  constructor(values: IBSTLPaymentRequestValues) {
    const appAlias = getAppAlias();
    const { origin } = window.location;
    const { provider, paymentMethod, caspecoInvoiceCompany } =
      values.providerPaymentMethod;

    this.paymentProviderId = provider;
    this.paymentToken = '';
    this.acceptUrl = `${origin}/${appAlias}/checkout/${values.orderId}?type=accept${values.registeredUserPurchase ? '&registeredUserPurchase=true' : ''}`;
    this.cancelUrl = BSTLPaymentRequest.buildCancelUrl(
      document.URL,
      paymentMethod,
    );
    this.method = paymentMethod;
    this.caspecoInvoice = caspecoInvoiceCompany
      ? {
          contactId: caspecoInvoiceCompany.contactId,
          customerId: caspecoInvoiceCompany.customerId,
          marking: values?.marking,
        }
      : null;
  }

  private static buildCancelUrl(originalUrL: string, method: string): string {
    let queryArgsSeparator = '?';

    if (originalUrL.includes('?')) {
      queryArgsSeparator = '&';
    }

    return `${document.URL}${queryArgsSeparator}type=cancel&method=${method}`;
  }
}
