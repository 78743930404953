import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  ThemeColorVariable,
  ThemeFontSizeVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { Stack } from '@caspeco/casper-ui-library.components.stack';
import { Button } from '@caspeco/casper-ui-library.components.button';
import { Text } from '@caspeco/casper-ui-library.components.text';
import { useCartAPI, useCartStore } from 'store/cartStore';
import { useAuthAPI } from 'store/authStore';
// eslint-disable-next-line import/no-named-as-default
import styled from 'styled-components';
import { getLocalStorage } from 'helpers/local-storage-helper/local-storage-helper';
import { IAppLinks } from 'types/app';
import { IRegisterUserFormFields } from './types';
import { EmailField } from './EmailField';
import { NameField } from './NameField';
import { PhoneField } from './PhoneField';

const StyledInlineLink = styled.a`
  color: ${ThemeColorVariable.OnSurfaceSubdued};
  text-decoration: underline;

  &:hover {
    color: ${ThemeColorVariable.OnSurface};
  }
`;

interface IRegisterUserForm {
  onCreateUser: (formData: IRegisterUserFormFields) => Promise<void>;
  defaultValues?: IRegisterUserFormFields;
  isEmailConfirmed: boolean;
  onClose: () => void;
}

export function RegisterUserForm({
  onCreateUser,
  defaultValues,
  isEmailConfirmed,
  onClose,
}: IRegisterUserForm) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { appAlias, menuId, placeId } = useParams<
    'appAlias' | 'menuId' | 'placeId'
  >();
  const { cart } = useCartStore();
  const { deleteCart } = useCartAPI();
  const { deleteUser, signInAnonymously, signOut } = useAuthAPI();
  const appLinks = getLocalStorage<IAppLinks>('links') ?? undefined;
  const [isCanceling, setIsCanceling] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<IRegisterUserFormFields>({
    mode: 'onSubmit',
    defaultValues,
    reValidateMode: 'onChange',
  });

  const onSubmit = async (formData: IRegisterUserFormFields) => {
    await onCreateUser(formData);
  };

  // When calling cancel at this point in the app we have a signed in but unregistered user
  // We don't want to keep this user or their cart, so we delete them at this point
  const onCancel = async () => {
    setIsCanceling(true);
    try {
      if (cart) {
        await deleteCart(cart.cartId);
      }
      await deleteUser();
      await signInAnonymously();
      setIsCanceling(false);
      onClose();

      if (location.pathname === `/${appAlias}/${placeId}/${menuId}/checkout`) {
        // User is canceling registration from checkout, the checkout form will turn unuseable
        // better to redirect them back to the menu
        navigate(`/${appAlias}/${placeId}/${menuId}${location.search}`);
      }
    } catch {
      // Some part of delete flow failed, fall back to signing out and signing in again anonymously
      // and redirect the user to the start page
      await signOut();
      await signInAnonymously();
      setIsCanceling(false);
      onClose();
      navigate(`/${appAlias}`);
    }
  };

  return (
    <>
      <form
        id="register-user-form"
        name="register-user-form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate // Turn off native behaviour, handled by useForm
      >
        <Stack
          spacing={ThemeSpaceVariable.Medium}
          mt={ThemeSpaceVariable.Large}
        >
          <EmailField control={control} isReadOnly={isEmailConfirmed} />
          <NameField control={control} />
          <PhoneField control={control} />
        </Stack>
        <Stack mt={ThemeSpaceVariable.Large} mb={ThemeSpaceVariable.Small}>
          <Button
            width="100%"
            type="submit"
            variant="primary"
            isLoading={isSubmitting}
          >
            {t('account_register_account')}
          </Button>
          <Button variant="ghost" isLoading={isCanceling} onClick={onCancel}>
            <span>
              {cart ? t('action_cancel_purchase') : t('action_cancel')}
            </span>
          </Button>
        </Stack>
        {appLinks?.privacyPolicyURL && (
          <Text
            textAlign="left"
            fontSize={ThemeFontSizeVariable.Small}
            color={ThemeColorVariable.OnSurfaceSubdued}
          >
            {t('account_accept_privacy_policy')}
            <StyledInlineLink target="_blank" href={appLinks.privacyPolicyURL}>
              {t('app_the_privacy_policy')}.
            </StyledInlineLink>
          </Text>
        )}
      </form>
    </>
  );
}
