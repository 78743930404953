import {
  Skeleton,
  SkeletonText,
} from '@caspeco/casper-ui-library.components.skeleton';
import { Box } from '@caspeco/casper-ui-library.components.box';
import { Flex } from '@caspeco/casper-ui-library.components.flex';
import {
  ThemeFontSizeVariable,
  ThemeRadiusVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { Stack } from '@caspeco/casper-ui-library.components.stack';
import { Divider } from '@caspeco/casper-ui-library.components.divider';
import { CSSVarColumnWidthLarge } from 'components/global-styles/GlobalStyles';
import { AspectRatioWrapper } from 'components/aspect-ratio-wrapper';
import { AspectRatio } from 'types/ui';

export default function PlaceSkeleton() {
  return (
    <Flex
      justify="center"
      my={[ThemeSpaceVariable.None, null, ThemeSpaceVariable.XLarge]}
    >
      <Box
        maxW={{ base: '100%', md: CSSVarColumnWidthLarge }}
        w={{ base: '100%', md: CSSVarColumnWidthLarge }}
      >
        <AspectRatioWrapper aspectratio={AspectRatio.SixteenByNine}>
          <Skeleton
            w="100%"
            borderRadius={{
              base: ThemeRadiusVariable.None,
              sm: ThemeRadiusVariable.Medium,
            }}
          />
        </AspectRatioWrapper>
        <Box
          py={ThemeSpaceVariable.Medium}
          px={{
            base: ThemeSpaceVariable.Medium,
            md: ThemeSpaceVariable.None,
          }}
        >
          <Stack
            spacing={ThemeSpaceVariable.Large}
            my={ThemeSpaceVariable.Medium}
          >
            <Skeleton h="25px" w="30%" />
            <Skeleton h="20px" w="50%" />
          </Stack>
          <Flex
            direction={['column', 'row']}
            gap={ThemeSpaceVariable.Medium}
            my={ThemeSpaceVariable.Large}
          >
            <Skeleton
              h="56px"
              w="100%"
              borderRadius={ThemeRadiusVariable.XLarge}
            />
            <Skeleton
              h="56px"
              w="100%"
              borderRadius={ThemeRadiusVariable.XLarge}
            />
          </Flex>
          <Divider />
          <Box my={ThemeSpaceVariable.XLarge}>
            <Skeleton
              w="30%"
              mb={ThemeSpaceVariable.Medium}
              h={ThemeFontSizeVariable.XLarge}
            />
            <SkeletonText
              skeletonHeight={ThemeFontSizeVariable.Medium}
              lineHeight="40px"
              noOfLines={8}
            />
          </Box>
          <Divider />
          <Box my={ThemeSpaceVariable.XLarge}>
            <Skeleton
              h={ThemeFontSizeVariable.XLarge}
              w="30%"
              mb={ThemeSpaceVariable.Medium}
            />
            <SkeletonText
              noOfLines={6}
              skeletonHeight={ThemeFontSizeVariable.Medium}
            />
          </Box>
          <Divider />
          <Box my={ThemeSpaceVariable.XLarge}>
            <Skeleton
              h={ThemeFontSizeVariable.XLarge}
              w="30%"
              mb={ThemeSpaceVariable.Medium}
            />
            <SkeletonText
              noOfLines={4}
              skeletonHeight={ThemeFontSizeVariable.Medium}
            />
          </Box>
        </Box>
      </Box>
    </Flex>
  );
}
