import { createGlobalStyle } from 'styled-components';
import { BreakPoint } from 'types/ui';

// Strings to expose and re-use throughout the application, unique for Online Order Web
export const CSSVarHeaderHeight = 'var(--header-height)';
export const CSSVarColumnWidthLarge = 'var(--column-width-large)';
export const CSSVarColumnWidthSmall = 'var(--column-width-small)';

export const GlobalStyles = createGlobalStyle`
html {
  --column-width-small: 360px;
  --column-width-large: 640px;
  --header-height: 88px;
  
  @media screen and (min-width: ${BreakPoint.Medium}) { 
    --header-height: 112px;
  }
  height: 100%;
  margin: 0;
}

// Prevents alt text from flashing during image load in firefox
img:-moz-loading {
  visibility: hidden;
}

body {
  font-family: var(--font-body), -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira sans', 'Droid sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

pre {
  margin: 0;
}

.visually-hidden {
  /* Not visible in view but still accessible to screen readers */
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

// Apply to card/container element with a link within
// Used make the whole card/container clickable while still making sure 
// the actual link content is only relevant text
.link-card, .button-card {
  position: relative;
}

.link-card a[href]::after,
.button-card button::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
`;
