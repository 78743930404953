import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { DeliveryType } from 'types/deliveryOption';
import {
  findDeliveryNodeInRoot,
  getFullDeliveryOptionSelection,
} from 'helpers/deliveryoptions-helper/deliveryoptions-helper';
import { SkeletonDeliveryOptions } from 'components/skeletons/SkeletonDeliveryOptions';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from '@caspeco/casper-ui-library.components.modal';
import { Text } from '@caspeco/casper-ui-library.components.text';
import { Button } from '@caspeco/casper-ui-library.components.button';
import {
  ThemeColorVariable,
  ThemeFontSizeVariable,
  ThemeFontVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { deleteLocalStorage } from 'helpers/local-storage-helper/local-storage-helper';
import { usePickSearchParams } from 'hooks/usePickSearchParams';
import { Heading } from '@caspeco/casper-ui-library.components.heading';
import { usePlaceStore } from 'store/placeStore';
import { useDeliveryOptionsStore } from 'store/deliveryOptionsStore';
import { DeliveryForm } from './subcomponents/form/DeliveryForm';
import { IDeliveryFormValues } from './subcomponents/form/DeliveryForm.types';

interface IDeliveryOptionsModal {
  isOpen: boolean;
  onClose: () => void;
  isCloseButtonVisible?: boolean;
  onCancel?: () => void;
  /** Delivery types available based on menu-/place-context */
  deliveryTypes?: DeliveryType[];
  isOpenedFromPlaceRoute?: boolean;
  onSubmit: (
    type: DeliveryType | undefined,
    resourceId?: string,
    resourceName?: string,
  ) => void;
}

function DeliveryOptionsModal({
  onClose,
  isCloseButtonVisible = true,
  onCancel,
  deliveryTypes,
  isOpenedFromPlaceRoute,
  isOpen,
  onSubmit,
}: IDeliveryOptionsModal) {
  const {
    deliveryOptionType: deliveryOptionTypeInSearchParams,
    deliveryOptionId: deliveryOptionIdInSearchParams,
  } = usePickSearchParams(['deliveryOptionType', 'deliveryOptionId']);
  const navigate = useNavigate();
  const { appAlias, placeId, menuId } = useParams<
    'appAlias' | 'placeId' | 'menuId'
  >();
  const { t } = useTranslation();
  const { rootDeliveryOption, hasLoadedRootDeliveryOption } =
    useDeliveryOptionsStore();
  const { place } = usePlaceStore();
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    setHasError(false);
    if (place?.eatHereRootResourceId && !rootDeliveryOption) {
      setHasError(true);
    }
  }, [place, rootDeliveryOption]);

  const getOutmostSelectedOptionId = (
    levelOne?: string,
    levelTwo?: string,
    levelThree?: string,
  ): string | undefined => {
    // Find the final selected option, look at all levels to find the outmost one.
    if (Boolean(levelOne) && !levelTwo) {
      return levelOne;
    }
    if (Boolean(levelTwo) && !levelThree) {
      return levelTwo;
    }
    if (levelThree) {
      return levelThree;
    }
    return undefined;
  };

  const onGoBack = () => {
    if (placeId && menuId) {
      // Is on menu route, going back = go to place route
      navigate(`/${appAlias}/${placeId}`);
      return;
    }
    // Fallback to start page
    navigate(`/${appAlias}`);
  };

  const handleSubmit = (formData: IDeliveryFormValues) => {
    const { type } = formData;
    const resourceId = getOutmostSelectedOptionId(
      formData.levelOneSelection,
      formData.levelTwoSelection,
      formData.levelThreeSelection,
    );

    const resourceName =
      rootDeliveryOption && resourceId
        ? findDeliveryNodeInRoot(rootDeliveryOption, resourceId)?.title
        : undefined;

    // Delete any references to (now irrelevant) delivery options from resolved app link data
    deleteLocalStorage('resolvedAppLink');
    onSubmit(type, resourceId, resourceName);
  };

  const getDefaultFormValues = (): IDeliveryFormValues => {
    const { levelOne, levelTwo, levelThree, type } =
      getFullDeliveryOptionSelection(
        rootDeliveryOption,
        deliveryTypes,
        deliveryOptionIdInSearchParams,
        deliveryOptionTypeInSearchParams as DeliveryType,
      );

    return {
      type,
      levelOneSelection: levelOne?.id ?? '',
      levelTwoSelection: levelTwo?.id ?? '',
      levelThreeSelection: levelThree?.id ?? '',
    };
  };

  let content;

  if (deliveryTypes?.length === 0 || hasError) {
    content = (
      <>
        <ModalHeader
          px={ThemeSpaceVariable.Medium}
          pt={ThemeSpaceVariable.Medium}
        >
          {t('error_generic')}
          {isCloseButtonVisible && (
            <ModalCloseButton isAbsolutePositioned={true} />
          )}
        </ModalHeader>
        <ModalBody p={ThemeSpaceVariable.Medium}>
          <Text mb={ThemeSpaceVariable.Large}>
            {t('error_get_delivery_options')}
          </Text>
          <Button onClick={onGoBack} w="100%" variant="primary" size="lg">
            {t('action_go_back')}
          </Button>
        </ModalBody>
      </>
    );
  } else if (place?.eatHereRootResourceId && !hasLoadedRootDeliveryOption) {
    content = <SkeletonDeliveryOptions />;
  } else {
    content = (
      <>
        <ModalHeader
          p={ThemeSpaceVariable.Medium}
          display="flex"
          justifyContent="space-between"
        >
          <Heading
            as="h2"
            fontFamily={ThemeFontVariable.SubHeader}
            fontWeight="medium"
            fontSize={ThemeFontSizeVariable.XLarge}
            color={ThemeColorVariable.OnSurfaceHeader}
          >
            {t('order_eat_where_question')}
          </Heading>
          {isCloseButtonVisible && <ModalCloseButton />}
        </ModalHeader>
        <ModalBody px={ThemeSpaceVariable.Medium}>
          <DeliveryForm
            onClose={onClose}
            onCancel={onCancel}
            onSubmit={handleSubmit}
            rootDeliveryOption={rootDeliveryOption}
            defaultValues={getDefaultFormValues()}
            deliveryTypes={deliveryTypes}
            isOpenedFromPlaceRoute={isOpenedFromPlaceRoute}
          />
        </ModalBody>
      </>
    );
  }

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      {content}
    </Modal>
  );
}

export default DeliveryOptionsModal;
