import {
  ThemeColorVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
// eslint-disable-next-line import/no-named-as-default
import styled from 'styled-components';
import { BreakPoint } from 'types/ui';

export const StyledArticleGrid = styled.div`
  display: grid;

  /* Ignore-reason: grid-template-columns falsely identified as "multicolumn" */
  /* stylelint-disable-next-line */
  grid-template-columns: 1fr;

  @media (min-width: ${BreakPoint.Small}) {
    /* Ignore-reason: grid-template-columns falsely identified as "multicolumn" */
    /* stylelint-disable-next-line */
    grid-template-columns: 1fr 1fr;
  }

  .articlegrid-item {
    align-items: center;
    border-bottom: 1px solid ${ThemeColorVariable.OnBackgroundBorder};
    color: ${ThemeColorVariable.OnBackground};
    display: flex;
    gap: ${ThemeSpaceVariable.Small};
    min-height: 120px;
    user-select: none;
    word-break: break-all;

    @media (hover: hover) {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .articlegrid-item-content {
    display: flex;
    justify-content: space-between;
    margin: ${ThemeSpaceVariable.Medium} 0;
    min-height: 80px;
  }

  /* Grid styles applying borders and spacing for articles */
  @media (min-width: ${BreakPoint.Small}) {
    .articlegrid-item:nth-child(even) {
      padding-left: ${ThemeSpaceVariable.Medium};
    }

    .articlegrid-item:nth-child(odd) .articlegrid-item-content {
      border-right: 1px solid ${ThemeColorVariable.OnBackgroundBorder};
      padding-right: ${ThemeSpaceVariable.Medium};
    }
  }
`;
