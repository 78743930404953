import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppStore } from 'store/appStore';

export const PageTitle = ({ title }: { title: string }) => {
  const location = useLocation();
  const { appData } = useAppStore();

  useEffect(() => {
    const prevTitle = document.title;

    if (title) {
      document.title = `${appData?.appName} ${title}`;
    }

    return () => {
      document.title = prevTitle;
    };
  }, [appData?.appName, location, title]);

  return null;
};
