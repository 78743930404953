// eslint-disable-next-line import/no-named-as-default
import styled from 'styled-components';
import { BreakPoint } from 'types/ui';

export const LogoContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  max-height: 72px;

  @media screen and (min-width: ${BreakPoint.Medium}) {
    max-height: 88px;
  }
`;
