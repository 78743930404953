import { useCallback, useState } from 'react';
import {
  BlockerFunction,
  useBlocker,
  useParams,
  Location,
} from 'react-router-dom';
import { AnalyticsService } from 'services/Analytics.service';
import { CheckoutNavigationBackWithTip } from 'types/analytics';
import { Cart } from 'types/cart';

/**
 * Guard to prevent user from navigating away from cart with items
 */
export function useCartGuard(cart: Cart | undefined) {
  const { placeId, menuId, appAlias } = useParams<
    'placeId' | 'menuId' | 'appAlias'
  >();
  // Keep and expose nextLocation to be used together with guard dialog component
  const [nextLocation, setNextLocation] = useState<Location | null>(null);

  // Check if user navigates back to menu from checkout with cart containing tip
  const checkForBackWithTipEvent = (
    currentLocation: Location,
    nextLocation: Location,
  ) => {
    const isCartWithTip = Boolean(cart && cart?.tipInfo.tipAmount.cents > 0);
    const isCurrentCheckoutRoute =
      currentLocation.pathname === `/${appAlias}/${placeId}/${menuId}/checkout`;
    const isNextMenuRoute =
      nextLocation.pathname === `/${appAlias}/${placeId}/${menuId}`;

    if (isCurrentCheckoutRoute && isNextMenuRoute && isCartWithTip) {
      const event = new CheckoutNavigationBackWithTip();
      AnalyticsService.sendEvent(event);
    }
  };

  const shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => {
      // Reset next location initally, we only want so set it anew when actually blocking
      setNextLocation(null);

      if (!cart) {
        return false;
      }
      // Temp analytics event check
      checkForBackWithTipEvent(currentLocation, nextLocation);

      const isNextLocationRouteWithCart =
        nextLocation.pathname === `/${appAlias}/${placeId}/${menuId}` ||
        nextLocation.pathname === `/${appAlias}/${placeId}/${menuId}/checkout`;

      if (isNextLocationRouteWithCart) {
        // Cart will exist on next location, no need to block
        return false;
      }

      if (cart.isEmpty()) {
        return false;
      }

      // Do block + expose nextLocation
      setNextLocation(nextLocation);
      return true;
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cart],
  );

  const blocker = useBlocker(shouldBlock);

  return { blocker, nextLocation };
}
