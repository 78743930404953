import { config } from 'config/app.config';
import {
  fetcher,
  IRequestOptions,
} from 'helpers/fetch-interceptor/fetch-interceptor';
import { BSTLApiError, IBSTLApiErrorResponse } from 'types/api-error';

export enum AbortAction {
  MENU_GET_ARTICLE = 'MENU_GET_ARTICLE',
  MENU_GET_UPSELL_GROUPS_FOR_ARTICLE = 'MENU_GET_UPSELL_GROUPS_FOR_ARTICLE',
  OFFER_GET = 'OFFER_GET',
  UPDATE_CART_ROW_QUANTITY = 'UPDATE_CART_ROW_QUANTITY',
}

type AbortActions = {
  [key in AbortAction]: AbortController | undefined;
};

const abortActionControllers: AbortActions = {
  [AbortAction.MENU_GET_ARTICLE]: undefined,
  [AbortAction.MENU_GET_UPSELL_GROUPS_FOR_ARTICLE]: undefined,
  [AbortAction.OFFER_GET]: undefined,
  [AbortAction.UPDATE_CART_ROW_QUANTITY]: undefined,
};

export class ApiService {
  /**
   * @param path string representing the path to fetch. Applied onto base url. For ex 'orders/${orderId}'
   * @param version Set api version, default is v1
   * @param options Optional request options.
   * @param blob Overrides default resolve behaviour to Blob.
   */
  static async fetch<T>(
    path: string,
    options?: IRequestOptions,
    blob?: boolean,
    version: 'v1' | 'v2' = 'v1',
    abortAction: AbortAction | undefined = undefined,
  ): Promise<T> {
    const patchedOptions = {
      ...options,
    };

    if (abortAction) {
      abortActionControllers[abortAction] = new AbortController();

      const signal = abortActionControllers[abortAction]?.signal;
      if (signal) {
        patchedOptions.signal = signal;
      }
    }

    return fetcher(
      `${config.baseUrl}/bstl-api/${version}/${path}`,
      patchedOptions,
    ).then(async (response) => {
      if (abortAction) {
        abortActionControllers[abortAction] = undefined;
      }

      let responseData;

      if (blob) {
        responseData = await response.blob();
      } else {
        responseData = await response
          .text()
          // Asserts that there is response content to parse,
          // otherwise null responses would throw parse error
          .then((text) => {
            try {
              return text && (JSON.parse(text) as T);
            } catch {
              return undefined;
            }
          });
      }

      if (response.ok) {
        return responseData as T;
      }
      throw new BSTLApiError(
        responseData as IBSTLApiErrorResponse,
        response.status,
      );
    });
  }

  static cancelRequest(actionAbortName: AbortAction) {
    abortActionControllers[actionAbortName]?.abort();
    abortActionControllers[actionAbortName] = undefined;
  }
}
