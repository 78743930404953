// eslint-disable-next-line import/no-named-as-default
import styled from 'styled-components';
import { AspectRatio } from 'types/ui';

type IAspectRatioWrapper = {
  aspectratio: AspectRatio;
};

export const AspectRatioWrapper = styled.div<IAspectRatioWrapper>`
  > * {
    aspect-ratio: ${(props) => props.aspectratio};
  }
`;
