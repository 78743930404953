import { ICampaignsValues, Campaigns } from './campaign';
import { CartArticle, ICartArticleValues } from './cartArticle';
import { DeliveryInfo, IDeliveryInfoValues } from './deliveryOption';
import {
  ProviderPaymentMethod,
  IProviderPaymentMethodResponse,
  PaymentMethod,
} from './payment';
import { PreferredReady, IPreferredReadyValues } from './preferredReady';
import { IPriceIncludingVatValues, PriceIncludingVat } from './price';
import { ITipInfoResponse, TipInfo } from './tip';

interface ICartRowValues {
  id: string;
  quantity: number;
  article: ICartArticleValues;
  totalAmountForRow: IPriceIncludingVatValues;
}

export class CartRow {
  id: string;
  quantity: number;
  article: CartArticle;
  totalAmountForRow: PriceIncludingVat;

  constructor(values: ICartRowValues) {
    this.id = values?.id ?? '';
    this.quantity = values?.quantity ?? 0;
    this.article = new CartArticle(values?.article);
    this.totalAmountForRow = new PriceIncludingVat(values?.totalAmountForRow);
  }
}

export class Cart {
  cartId: string;
  menuId: string;
  rows: CartRow[];
  totalAmount: PriceIncludingVat;
  totalAmountExcludingTip: PriceIncludingVat;
  tipInfo: TipInfo;
  message: string;
  availableProviderPaymentMethods: ProviderPaymentMethod[];
  preparationMinutes: number;
  preferredReady: PreferredReady;
  campaigns: Campaigns;
  totalAmountForArticleRows: PriceIncludingVat;
  deliveryInfo: DeliveryInfo;

  constructor(values: ICartResponse) {
    this.cartId = values?.cartId ?? '';
    this.menuId = values?.menuId ?? '';
    this.rows = values?.rows?.map((r) => new CartRow(r)) ?? [];
    this.totalAmount = new PriceIncludingVat(values?.totalAmount);
    this.totalAmountExcludingTip = new PriceIncludingVat(
      values?.totalAmountExcludingTip,
    );
    this.tipInfo = new TipInfo(values?.tipInfo);
    this.message = values?.message ?? '';
    this.availableProviderPaymentMethods = Cart.listProviderPaymentMethods(
      values?.availableProviderPaymentMethods,
    );
    this.preparationMinutes = values?.preparationMinutes ?? 0;
    this.preferredReady = new PreferredReady(values?.preferredReady);
    this.campaigns = new Campaigns(values?.campaigns);
    this.totalAmountForArticleRows = new PriceIncludingVat(
      values?.totalAmountForArticleRows,
    );
    this.deliveryInfo = new DeliveryInfo(values?.deliveryInfo);
  }

  isEmpty(): boolean {
    return this.rows.length === 0;
  }

  /**
   * Formats the provider payment response to a list that is easier applied to the UI.
   *
   * Mainly used to handle the INVOICE-method. It that can contain multiple nested entries,
   * but in the UI (Checkout Form) these entries are to be displayed
   * at the same level as the ProviderPaymentMethod (Swish, Card, etc).
   */
  private static listProviderPaymentMethods(
    responseItems?: IProviderPaymentMethodResponse[],
  ): ProviderPaymentMethod[] {
    if (!responseItems || responseItems?.length === 0) return [];
    let result: ProviderPaymentMethod[] = [];

    // Create a ProviderPaymentMethod for each response item that is not of type INVOICE
    result = responseItems
      .filter(
        (item) =>
          (item.paymentMethod as PaymentMethod) !== PaymentMethod.Invoice,
      )
      .map(
        (ppm) =>
          new ProviderPaymentMethod({
            provider: ppm.provider,
            paymentMethod: ppm.paymentMethod,
            sortOrder: ppm.sortOrder,
            caspecoInvoiceCompany: null,
          }),
      );

    // Identify the INVOICE provider method, if any (can only be one)
    const invoiceItem = responseItems.find(
      (item) => (item.paymentMethod as PaymentMethod) === PaymentMethod.Invoice,
    );

    if (invoiceItem) {
      // For each company in the INVOICE item, create a new ProviderPaymentMethod
      const invoiceItems = invoiceItem.caspecoInvoiceCompanies
        // Sort by internal company sortorder
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map(
          (company) =>
            new ProviderPaymentMethod({
              provider: 'caspecoinvoice',
              paymentMethod: 'INVOICE',
              sortOrder: invoiceItem.sortOrder,
              caspecoInvoiceCompany: company,
            }),
        );
      result.push(...invoiceItems);
    }

    result.sort((a, b) => a.sortOrder - b.sortOrder);
    return result;
  }
}

export interface ICartResponse {
  cartId: string;
  menuId: string;
  rows: ICartRowValues[];
  totalAmount: IPriceIncludingVatValues;
  totalAmountExcludingTip: IPriceIncludingVatValues;
  tipInfo: ITipInfoResponse;
  message: string;
  availableProviderPaymentMethods: IProviderPaymentMethodResponse[];
  preparationMinutes: number;
  preferredReady: IPreferredReadyValues;
  campaigns: ICampaignsValues;
  totalAmountForArticleRows: IPriceIncludingVatValues;
  deliveryInfo: IDeliveryInfoValues;
}

export interface ICartRowDraft {
  quantity: number;
  itemId: string;
  extras: string[];
  options: string[];
}

export class CartRowDraft {
  quantity: number;
  itemId: string;
  extras: string[];
  options: string[];

  constructor(values: ICartRowDraft) {
    this.quantity = values?.quantity ?? 1;
    this.itemId = values?.itemId;
    this.extras = values?.extras ?? [];
    this.options = values?.options ?? [];
  }
}
