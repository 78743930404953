import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  flattenDeliveryOptions,
  hasNodes,
} from 'helpers/deliveryoptions-helper/deliveryoptions-helper';
import { Button } from '@caspeco/casper-ui-library.components.button';
import { Flex } from '@caspeco/casper-ui-library.components.flex';
import { Text } from '@caspeco/casper-ui-library.components.text';
import { Stack } from '@caspeco/casper-ui-library.components.stack';
import {
  ThemeSpaceVariable,
  ThemeColorVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { useMinWidthMediaQuery } from 'hooks/useMediaQuery';
import { Box } from '@caspeco/casper-ui-library.components.box';
import { DeliveryOption, DeliveryType } from 'types/deliveryOption';
import { BreakPoint } from 'types/ui';
import { DeliveryOptionField } from './DeliveryOptionField';
import { IDeliveryFormValues } from './DeliveryForm.types';
import { DeliveryTypesField } from './DeliveryTypesField';

interface IDeliveryForm {
  onClose: () => void;
  onCancel?: () => void;
  onSubmit: (formData: IDeliveryFormValues) => void;
  defaultValues?: IDeliveryFormValues;
  rootDeliveryOption?: DeliveryOption;
  deliveryTypes?: DeliveryType[];
  isOpenedFromPlaceRoute?: boolean;
}

export function DeliveryForm({
  onClose,
  onCancel,
  onSubmit,
  defaultValues,
  rootDeliveryOption,
  deliveryTypes,
  isOpenedFromPlaceRoute,
}: IDeliveryForm) {
  const allDeliveryOptions = flattenDeliveryOptions(rootDeliveryOption);
  const { t } = useTranslation();
  const isLargerScreen = useMinWidthMediaQuery(BreakPoint.Small);
  const { handleSubmit, control, watch, reset, getValues, formState } =
    useForm<IDeliveryFormValues>({
      defaultValues,
      mode: 'onSubmit',
    });
  // Subscribe/'watch' fields that that should trigger conditional fields
  const type = watch('type');
  const levelOneSelection = watch('levelOneSelection');

  useEffect(() => {
    // Keep up with any changes to the default values
    reset(defaultValues);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  const getLevelOptions = (id?: string): DeliveryOption[] => {
    if (!id || !rootDeliveryOption) return [];
    return allDeliveryOptions.find((a) => a.id === id)?.deliveryOptions ?? [];
  };

  // Options to display per delivery option field
  const levelOneOptions = rootDeliveryOption?.deliveryOptions ?? [];
  const levelTwoOptions = getLevelOptions(levelOneSelection);

  const resetDescendingLevels = (
    value: string,
    fieldName: keyof IDeliveryFormValues,
  ) => {
    switch (fieldName) {
      // Delivery option TYPE change is considered changing the top level, resets all level selections
      case 'type': {
        /**
         * If there is only one level one option AND type is being changed to 'EAT_AT_RESTAURANT'
         * we select this option by default. It is equal to resetting level one.
         */
        let levelOneSelection = '';
        if (levelOneOptions.length === 1 && value === 'EAT_AT_RESTAURANT') {
          levelOneSelection = levelOneOptions[0].id;
        }
        reset({
          type: value as DeliveryType | undefined,
          levelOneSelection,
          levelTwoSelection: '',
          levelThreeSelection: '',
        });
        break;
      }
      case 'levelOneSelection':
        // Keep current type and new level one value, reset level below
        reset({
          type: getValues('type'),
          levelOneSelection: value,
          levelTwoSelection: '',
        });
        break;
      default:
        break;
    }
  };

  const handleCancelClick = () => {
    if (onCancel) onCancel();
    else onClose();
  };

  const isSingleDeliveryType = Boolean(
    deliveryTypes && deliveryTypes.length === 1,
  );

  const shouldShowDeliveryTypeField = () => {
    if (isOpenedFromPlaceRoute) {
      return false;
    }
    if (isSingleDeliveryType && hasNodes(rootDeliveryOption)) {
      return false;
    }
    return true;
  };

  return (
    /**
     * Note: The noValidate is passed to <form> to handle issue with native validation errors
     * preventing custom errors from showing. Relates to the following issue:
     * @see https://github.com/chakra-ui/chakra-ui/issues/4770#issuecomment-1338892063
     */
    <form onSubmit={handleSubmit(onSubmit)} noValidate name="delivery-options">
      {deliveryTypes && (
        <Controller
          name="type"
          control={control}
          rules={{
            required: {
              value: true,
              message: t('misc_select_where_to_eat'),
            },
          }}
          render={({ field, fieldState }) => (
            <DeliveryTypesField
              field={field}
              isSingleType={isSingleDeliveryType}
              isVisible={shouldShowDeliveryTypeField()}
              fieldState={fieldState}
              onChange={resetDescendingLevels}
              options={deliveryTypes}
            />
          )}
        />
      )}
      {isSingleDeliveryType && shouldShowDeliveryTypeField() && (
        <Text
          my={ThemeSpaceVariable.Medium}
          color={ThemeColorVariable.OnSurfaceSubdued}
        >
          {`*${t('menu_has_only_one_deliveryoption')}`}
        </Text>
      )}
      {/* EAT_AT_RESTAURANT is the only type currently supporting delivery option nodes */}
      {type === 'EAT_AT_RESTAURANT' && (
        <Stack spacing={ThemeSpaceVariable.Medium}>
          {type === 'EAT_AT_RESTAURANT' && levelOneOptions.length > 0 && (
            <DeliveryOptionField
              fieldName="levelOneSelection"
              isHidden={
                levelOneOptions.length === 1 && levelTwoOptions.length !== 0
              }
              options={levelOneOptions}
              onChange={resetDescendingLevels}
              control={control}
            />
          )}
          {Boolean(levelOneSelection) && levelTwoOptions.length > 0 && (
            <DeliveryOptionField
              fieldName="levelTwoSelection"
              options={levelTwoOptions}
              onChange={resetDescendingLevels}
              control={control}
            />
          )}
        </Stack>
      )}
      <Box
        my={ThemeSpaceVariable.Medium}
        display="flex"
        justifyContent="flex-end"
      >
        <Flex
          gap={ThemeSpaceVariable.Medium}
          w="100%"
          justifyContent="flex-end"
        >
          {isLargerScreen && (
            <Button type="button" onClick={handleCancelClick} variant="ghost">
              <Text
                color={ThemeColorVariable.OnSurface}
                fontWeight="inherit"
                fontFamily="inherit"
                fontSize="inherit"
              >
                {t('action_cancel')}
              </Text>
            </Button>
          )}
          <Button
            type="submit"
            variant="primary"
            size={{ base: 'lg', sm: 'md' }}
            w={{ base: '100%', sm: 'auto' }}
            isDisabled={formState.isSubmitting}
          >
            {t('action_save')}
          </Button>
        </Flex>
      </Box>
    </form>
  );
}
