import { createContext, useContext, useMemo, useReducer } from 'react';
import {
  addLogAttribute,
  logDebug,
  logError,
  logInfo,
  logWarn,
} from 'helpers/log-helper/log-helper';
import { CartService } from 'services/Cart.service';
import { deleteLocalStorage } from 'helpers/local-storage-helper/local-storage-helper';
import { BSTLApiError } from 'types/api-error';
import {
  getSessionStorage,
  setSessionStorage,
} from 'helpers/session-storage-helper/session-storage-helper';
import { deleteCartReferences } from 'helpers/cart-helper/cart-helper';
import { Cart, ICartRowDraft } from 'types/cart';
import { IChangeDeliveryTypeRequest } from 'types/deliveryOption';
import { LogPrefix } from 'types/logging';
import { OfferStatus } from 'types/offer';

interface ICartStore {
  readonly cart: undefined | Cart;
  /**
   * Is currently fetching cart
   */
  readonly isLoadingCart: boolean;
  /**
   * Has finished fetch request for cart,
   * true no matter if the response was an error or success
   */
  readonly hasLoadedCart: boolean;
  /**
   * Used to control full screen modal displaying cart contents,
   * false by default
   */
  readonly isCartModalOpen: boolean;
}

interface ICartAPI {
  /**
   * Create a new cart
   * @param menuId id of menu the guest wants to make a purchase from
   * @param deliveryOptionType selected delivery type
   * @param resourceId optional resource id (formally known/also refererred to as deliveryOptionId)
   */
  createCart: (
    menuId: string,
    deliveryOptionType: string,
    resourceId?: string,
  ) => Promise<Cart>;
  /**
   * Fetch cart for given id
   */
  getCart: (cartId: string) => Promise<Cart>;
  /**
   * Delete cart for given id
   */
  deleteCart: (cartId: string) => Promise<void>;
  /**
   * Add row with item to cart
   */
  addCartRow: (cartId: string, row: ICartRowDraft) => Promise<void>;
  /**
   * Delete cart row
   */
  deleteCartRow: (cartId: string, cartRowId: string) => Promise<void>;
  /**
   * Update existing cart row
   */
  updateCartRow: (
    cartId: string,
    rowId: string,
    cartRow: ICartRowDraft,
  ) => Promise<void>;

  updateCartRowQuantity: (
    cartId: string,
    rowId: string,
    quantity: number,
  ) => Promise<Cart | undefined>;
  /**
   * Update delivery type for existing cart.
   */
  setCartDeliveryType: (
    cartId: string,
    request: IChangeDeliveryTypeRequest,
  ) => Promise<void>;
  /**
   * Apply tip to cart
   */
  setCartTip: (cartId: string, tipInCents: number) => Promise<void>;
  /**
   * Apply message to cart
   */
  setCartMessage: (cartId: string, message?: string) => Promise<void>;
  /**
   * Apply campaign code to cart.
   * Using null value will reset current campaign code applied to cart if any.
   */
  setCartCampaignCode: (cartId: string, code: string | null) => Promise<void>;
  /**
   * Apply preferred time when order for cart should be ready.
   * Using null value will reset current prefferred ready time if any.
   */
  setCartPreferredReadyTime: (
    cartId: string,
    preferredReadyTime: string,
  ) => Promise<void>;
  /**
   * Use to control full screen modal displaying cart contents
   */
  setIsCartModalOpen: (isOpen: boolean) => void;
  /**
   * Sets the current user (identified via bearer access token, see fetch interceptor)
   * as the owner of existing cart
   */
  changeCartUser: (cartId: string) => Promise<Cart | undefined>;
  /**
   * Activate/deactivate offer on cart
   */
  setCartOffer: (
    cartId: string,
    offerId: string,
    offerName: string,
    offerStatus: OfferStatus,
  ) => Promise<Cart>;
}

enum ActionType {
  SET_CART,
  START_LOADING_CART,
  STOP_LOADING_CART,
  SET_CART_MODAL_OPEN,
}

type Action =
  | { type: ActionType.SET_CART; payload: Cart | undefined }
  | { type: ActionType.START_LOADING_CART }
  | { type: ActionType.STOP_LOADING_CART }
  | { type: ActionType.SET_CART_MODAL_OPEN; payload: boolean };

const CartStore = createContext<ICartStore>({} as ICartStore);
const CartAPI = createContext<ICartAPI>({} as ICartAPI);

const initialState: ICartStore = {
  cart: undefined,
  isCartModalOpen: false,
  isLoadingCart: false,
  hasLoadedCart: false,
};

const reducer = (state: ICartStore, action: Action): ICartStore => {
  switch (action.type) {
    case ActionType.SET_CART:
      return {
        ...state,
        cart: action.payload,
        isLoadingCart: false,
        hasLoadedCart: true,
      };
    case ActionType.START_LOADING_CART:
      return {
        ...state,
        isLoadingCart: true,
        hasLoadedCart: false,
      };
    case ActionType.STOP_LOADING_CART:
      return {
        ...state,
        isLoadingCart: false,
        hasLoadedCart: true,
      };
    case ActionType.SET_CART_MODAL_OPEN:
      return {
        ...state,
        isCartModalOpen: action.payload,
      };
    default:
      return state;
  }
};

export function CartProvider({
  children,
  values = initialState,
}: {
  children: React.ReactNode;
  /** Initial state values, used for testing. */
  values?: ICartStore;
}) {
  const [state, dispatch] = useReducer(reducer, values);

  const api: ICartAPI = useMemo(() => {
    const createCart = async (
      menuId: string,
      deliveryOptionType: string,
      resourceId?: string,
    ) => {
      const initiatedFromAppLink =
        getSessionStorage<boolean>('initiatedFromAppLink') ?? false;
      const initiatedFromAppLinkId =
        getSessionStorage<string>('initiatedFromAppLinkId') ?? undefined;

      try {
        dispatch({ type: ActionType.START_LOADING_CART });
        const cart = await CartService.createCart(
          menuId,
          deliveryOptionType,
          resourceId,
          initiatedFromAppLink,
          initiatedFromAppLinkId,
        );

        // If guest was redirected to page by resolved link with delivery info,
        // we now want to remove that reference when successfully creating a cart.
        // This is to avoid having potential multiple references to delivery type/node (cart + link).
        deleteLocalStorage('resolvedAppLink');

        // Track cart id in logging + store in session
        setSessionStorage('cartId', cart.cartId);
        addLogAttribute('cartId', cart.cartId);
        logInfo(LogPrefix.Cart, `Cart created with Id: ${cart.cartId}`);

        dispatch({ type: ActionType.SET_CART, payload: cart });
        return cart;
      } catch (error) {
        dispatch({ type: ActionType.STOP_LOADING_CART });
        logError(LogPrefix.Cart, error, 'Could not create Cart');
        throw error;
      }
    };

    const deleteCart = async (cartId: string) => {
      try {
        // Remove local references to cart no matter if delete request fails
        dispatch({ type: ActionType.SET_CART, payload: undefined });
        deleteCartReferences();
        await CartService.deleteCart(cartId);
        logDebug(LogPrefix.Cart, `Cart ${cartId} deleted`);
      } catch (error) {
        if (error instanceof BSTLApiError && error.statusCode === 404) {
          // Expected when cart has been removed, just use log as debug log type
          logDebug(LogPrefix.Cart, 'Found no Cart to delete');
        } else if (error instanceof BSTLApiError && error.statusCode === 403) {
          logWarn(
            LogPrefix.Cart,
            'Access denied to delete Cart, only local cart references were removed',
          );
        } else {
          logError(LogPrefix.Cart, error, 'Could not delete Cart');
        }
        // Not an error we want the ui to respond to, don't throw it further down
      }
    };

    const addCartRow = async (cartId: string, cartRow: ICartRowDraft) => {
      try {
        dispatch({ type: ActionType.START_LOADING_CART });

        const updatedCart = await CartService.addCartRow(cartId, cartRow);
        dispatch({ type: ActionType.SET_CART, payload: updatedCart });
      } catch (error) {
        dispatch({ type: ActionType.STOP_LOADING_CART });
        // This error is logged further down in ui to access name of item added
        throw error;
      }
    };

    const deleteCartRow = async (cartId: string, cartRowId: string) => {
      try {
        dispatch({ type: ActionType.START_LOADING_CART });
        const updatedCart = await CartService.deleteCartRow(cartId, cartRowId);
        dispatch({
          type: ActionType.SET_CART,
          payload: updatedCart,
        });
      } catch (error) {
        dispatch({ type: ActionType.STOP_LOADING_CART });
        logError(
          LogPrefix.Cart,
          error,
          `Could not delete Cart Row with CartRowId: ${cartRowId}`,
        );
        throw error;
      }
    };

    const updateCartRow = async (
      cartId: string,
      rowId: string,
      cartRow: ICartRowDraft,
    ) => {
      try {
        dispatch({ type: ActionType.START_LOADING_CART });
        const updatedCart = await CartService.updateCartRow(
          cartId,
          rowId,
          cartRow,
        );
        dispatch({
          type: ActionType.SET_CART,
          payload: updatedCart,
        });
      } catch (error) {
        dispatch({ type: ActionType.STOP_LOADING_CART });
        logError(
          LogPrefix.Cart,
          error,
          `Could not update Cart Row with ArticleId: ${cartRow.itemId}`,
        );
        throw error;
      }
    };

    const updateCartRowQuantity = async (
      cartId: string,
      rowId: string,
      quantity: number,
    ): Promise<Cart | undefined> => {
      try {
        dispatch({ type: ActionType.START_LOADING_CART });

        const updatedCart = await CartService.updateCartRowQuantity(
          cartId,
          rowId,
          quantity,
        );

        dispatch({
          type: ActionType.SET_CART,
          payload: updatedCart,
        });

        return updatedCart;
      } catch (error) {
        dispatch({ type: ActionType.STOP_LOADING_CART });
        logError(
          LogPrefix.Cart,
          error,
          `Could not update Cart Row Quantity with rowId: ${rowId}`,
        );
        throw error;
      }
    };

    const setCartDeliveryType = async (
      cartId: string,
      request: IChangeDeliveryTypeRequest,
    ) => {
      try {
        dispatch({ type: ActionType.START_LOADING_CART });
        const updatedCart = await CartService.setCartDeliveryType(
          cartId,
          request,
        );
        dispatch({
          type: ActionType.SET_CART,
          payload: updatedCart,
        });
      } catch (error) {
        dispatch({ type: ActionType.STOP_LOADING_CART });
        logError(
          LogPrefix.Cart,
          error,
          'Could not update Delivery Type for Cart',
        );
        throw error;
      }
    };

    const setCartTip = async (cartId: string, tipInCents: number) => {
      try {
        dispatch({ type: ActionType.START_LOADING_CART });
        const updatedCart = await CartService.setTipOnCart(cartId, tipInCents);
        dispatch({
          type: ActionType.SET_CART,
          payload: updatedCart,
        });
      } catch (error) {
        dispatch({ type: ActionType.STOP_LOADING_CART });
        logError(
          LogPrefix.Cart,
          error,
          `Could not set Tip: ${tipInCents} on cart`,
        );
        throw error;
      }
    };

    const setCartMessage = async (cartId: string, message?: string) => {
      try {
        dispatch({ type: ActionType.START_LOADING_CART });
        const updatedCart = await CartService.setMessageOnCart(
          cartId,
          message ?? '',
        );
        dispatch({ type: ActionType.SET_CART, payload: updatedCart });
      } catch (error) {
        dispatch({ type: ActionType.STOP_LOADING_CART });
        logError(LogPrefix.Cart, error, 'Could not set Message for Cart');
        throw error;
      }
    };

    const setCartCampaignCode = async (cartId: string, code: string | null) => {
      try {
        dispatch({ type: ActionType.START_LOADING_CART });
        const updatedCart = await CartService.setCampaignCodeOnCart(
          cartId,
          code,
        );
        dispatch({ type: ActionType.SET_CART, payload: updatedCart });
      } catch (error) {
        dispatch({ type: ActionType.STOP_LOADING_CART });
        logError(
          LogPrefix.Cart,
          error,
          `Could not set Campaign Code: ${code} for cart`,
        );
        throw error;
      }
    };

    const setCartPreferredReadyTime = async (
      cartId: string,
      preferredReadyTime: string,
    ) => {
      try {
        // Asap ("Snarast") should be represented as a null value in our request to backend
        const preferredReadyTimeRequestValue =
          preferredReadyTime === 'asap' ? null : preferredReadyTime;

        dispatch({ type: ActionType.START_LOADING_CART });
        const updatedCart = await CartService.setPreferredReadyTimeOnCart(
          cartId,
          preferredReadyTimeRequestValue,
        );
        dispatch({ type: ActionType.SET_CART, payload: updatedCart });
      } catch (error) {
        dispatch({ type: ActionType.STOP_LOADING_CART });
        logError(
          LogPrefix.Cart,
          error,
          `Could not set Preferred Ready Time: ${preferredReadyTime} on Cart`,
        );
        throw error;
      }
    };

    const changeCartUser = async (cartId: string) => {
      try {
        dispatch({ type: ActionType.START_LOADING_CART });
        const updatedCart = await CartService.changeCartUser(cartId);
        setSessionStorage('cartId', cartId);
        dispatch({ type: ActionType.SET_CART, payload: updatedCart });
        logDebug(LogPrefix.Cart, 'Successfully changed user for Cart');
        return updatedCart;
      } catch (error) {
        dispatch({ type: ActionType.STOP_LOADING_CART });
        logError(LogPrefix.Cart, error, `Could not update user for Cart`);
        return undefined;
      }
    };

    const setCartOffer = async (
      cartId: string,
      offerId: string,
      offerName: string,
      offerStatus: OfferStatus,
    ) => {
      const statusVerb = offerStatus === 'ACTIVE' ? 'Activate' : 'Deactivate';
      try {
        dispatch({ type: ActionType.START_LOADING_CART });
        const updatedCart = await CartService.setCartOffer(
          cartId,
          offerId,
          offerStatus,
        );
        dispatch({ type: ActionType.SET_CART, payload: updatedCart });
        logDebug(LogPrefix.Cart, `${statusVerb}d Offer: "${offerName}"`);
        return updatedCart;
      } catch (error) {
        dispatch({ type: ActionType.STOP_LOADING_CART });
        logError(
          LogPrefix.Cart,
          error,
          `Could not ${statusVerb} Offer: "${offerName}"`,
        );
        throw error;
      }
    };

    const getCart = async (cartId: string) => {
      try {
        dispatch({ type: ActionType.START_LOADING_CART });

        const cart = await CartService.getCart(cartId);
        dispatch({ type: ActionType.SET_CART, payload: cart });

        // Track + store cart id in logging (if not already tracked)
        setSessionStorage('cartId', cart.cartId);
        addLogAttribute('cartId', cart.cartId);

        return cart;
      } catch (error) {
        dispatch({ type: ActionType.STOP_LOADING_CART });
        logError(LogPrefix.Cart, error, `Could not get Cart: ${cartId}`);

        if (error instanceof BSTLApiError && error.statusCode === 404) {
          // Cart does not exist anymore, trying to fetch it again will result in the same error
          // delete any references to avoid this error being thrown again
          deleteCartReferences();
        }
        throw error;
      }
    };

    const setIsCartModalOpen = (isOpen: boolean) => {
      dispatch({ type: ActionType.SET_CART_MODAL_OPEN, payload: isOpen });
    };

    return {
      createCart,
      getCart,
      deleteCart,
      addCartRow,
      deleteCartRow,
      updateCartRow,
      updateCartRowQuantity,
      setCartDeliveryType,
      setCartTip,
      setCartMessage,
      setCartCampaignCode,
      setCartPreferredReadyTime,
      setIsCartModalOpen,
      changeCartUser,
      setCartOffer,
    };
  }, []);

  return (
    <CartAPI.Provider value={api}>
      <CartStore.Provider value={state}>{children}</CartStore.Provider>
    </CartAPI.Provider>
  );
}

export const useCartStore = (): ICartStore => useContext(CartStore);
export const useCartAPI = (): ICartAPI => useContext(CartAPI);
